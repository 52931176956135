<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            360 Clash
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Congratulations!
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          You have successfully registered. Please check your email for
          confirmation of your registration.
        </b-card-text>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardTitle, BLink, VBTooltip,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
