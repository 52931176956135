var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" 360 Clash ")])]), _c('b-card-title', {
    staticClass: "mb-1 text-center"
  }, [_vm._v(" Congratulations! ")]), _c('b-card-text', {
    staticClass: "mb-2 text-center"
  }, [_vm._v(" You have successfully registered. Please check your email for confirmation of your registration. ")]), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Back to login ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }